<template>
	<div class="page-outboxbox">
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">SMS Outbox</h1>
			</div>
			
			<div class="column is-10 is-offset-1">
				<div class="box">
					<h2 class="subtitle">Send SMS</h2>
			
					<table class="table is-fullwidth">
						<thead>
							<tr>
								<th>#</th>
								<th>Send to</th>
								<th>Text</th>
								<th>Status</th>
								<th></th>
							</tr>
						</thead>
			
						<tbody>
							<tr
								v-for="smsText in outbox"
								v-bind:key="smsText.id"
							>
								<td>{{ smsText.id }}</td>
								<td>{{ smsText.destinationnumber }}</td>
								<td>{{ smsText.textdecoded }}</td>
								<td>{{ smsText.status }}</td>
								<td>
									<router-link :to="{ name: 'Outbox', params: { id: smsText.id }}">Details</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Outbox',
	data() {
		return {
			outbox: [],
		}
	},
	mounted() {
		this.getOutbox()
	},
	methods: {
		getOutbox() {
			axios
				.get('/api/v1/outbox/')
				.then(response => {
					for (let i = 0; i < response.data.length; i++) {
						this.outbox.push(response.data[i])
					}
				})
				.catch(error => {
					console.log(JSON.stringify(error))
				})
		}
	},
}	
</script>
