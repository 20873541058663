<template>
	<div class="page-add-sms">
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">Add SMS</h1>
			</div>
			
			<div class="column is-8 is-offset-2">
				<div class="field">
					<label>Send to</label>
					
					<div class="control">
						<input type="text" name="destinationnumber" class="input" v-model="sms.destinationnumber">
					</div>
				</div>
							
				<div class="field">
					<label>Text</label>
					
					<div class="control">
						<textarea name="textdecoded" class="textarea"  v-model="sms.textdecoded"></textarea>
					</div>
				</div>
			</div>

			<div class="column is-8 is-offset-2">
				<div class="field">
					<div class="control">
						<button class="button is-success" @click="submitForm">Submit</button>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'


export default {
	name: 'AddSms',
	data() {
		return {
			sms: {},
		}
	},
	methods: {
		submitForm() {
			const d = new Date();
			this.sms.creatorid = "AirSMS"
			this.sms.coding = "Default_No_Compression"
			this.sms.updatedindb = d.toISOString()
			this.sms.insertintodb = d.toISOString()
			this.sms.sendingdatetime = d.toISOString()
			this.sms.sendbefore = "23:59"
			this.sms.sendafter = "00:00"
			this.sms.multipart = 0
			this.sms.sendingtimeout = d.toISOString()			
			this.sms.status = "SendingOK"
			this.sms.statuscode = 0
			
			
			axios
				.post("/api/v1/outbox/", this.sms)
				.then(response => {
					toast({
						message: 'The sms was added',
						type: 'is-success',
						dismissible: true,
						pauseOnHover: true,
						duration: 2000,
						position: 'bottom-right',
					})
					
					this.$router.push('/sms/outbox')
				})
				.catch(error => {
					console.log(JSON.stringify(error))
				})
		}
	},
}	
</script>

<!-- 
{
	"destinationnumber": "23115621",
	"textdecoded": "SMS sent with gammu-api-server and Postman",
	"creatorid": "Postman",
	"coding": "Default_No_Compression",
	"updatedindb": "2022-02-14T07:52",
	"insertintodb": "2022-02-14T07:52",
	"sendingdatetime": "2022-02-14T07:52",
	"sendbefore": "23:59",
	"sendafter": "00:00",
	"multipart": 0,
	"sendingtimeout": "2022-02-14T23:59",
	"status": "SendingOK",
	"statuscode": 0
}

{
	"updatedindb": [
		"This field is required."
	],
	"insertintodb": [
		"This field is required."
	],
	"sendingdatetime": [
		"This field is required."
	],
	"sendbefore": [
		"This field is required."
	],
	"sendafter": [
		"This field is required."
	],
	"status": [
		"This field is required."
	],
	"statuscode": [
		"This field is required."
	]
}
-->