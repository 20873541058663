<template>
	<div class="page-inbox-sms">
		
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">SMS Inbox</h1>
			</div>

			<div class="column is-10 is-offset-1">
				<div class="box">
					<h2 class="subtitle">Details</h2>
					
					<table class="table is-fullwidth">
						<thead>
							<tr>
								<th>Key</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>ID:</td>
								<td>{{ inboxsms.id }}</td>
							</tr>
							<tr>
								<td>Received:</td>
								<td>{{ inboxsms.receivingdatetime }}</td>
							</tr>
							<tr>
								<td>Sender:</td>
								<td>{{ inboxsms.sendernumber }}</td>
							</tr>
							<tr>
								<td>Text:</td>
								<td>{{ inboxsms.textdecoded }}</td>
							</tr>
							<tr>
								<td>Coding:</td>
								<td>{{ inboxsms.coding }}</td>
							</tr>
							<tr>
								<td>Udh:</td>
								<td>{{ inboxsms.udh }}</td>
							</tr>
							<tr>
								<td>To number:</td>
								<td>{{ inboxsms.smscnumber }}</td>
							</tr>
							<tr>
								<td>Class:</td>
								<td>{{ inboxsms.class_field }}</td>
							</tr>
							<tr>
								<td>Recipientid:</td>
								<td>{{ inboxsms.recipientid }}</td>
							</tr>
							<tr>
								<td>Text raw:</td>
								<td>{{ inboxsms.text }}</td>
							</tr>
							<tr>
								<td>Updated in db:</td>
								<td>{{ inboxsms.updatedindb }}</td>
							</tr>
							<tr>
								<td>Processed:</td>
								<td>{{ inboxsms.processed }}</td>
							</tr>
							<tr>
								<td>Status:</td>
								<td>{{ inboxsms.status }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'InboxSms',
	data () {
		return {
			inboxsms: {},
		}
	},
	mounted() {
		this.getInboxSms()
	},
	methods: {
		getInboxSms() {
			const inboxsmsID = this.$route.params.id

			axios
				.get(`/api/v1/inbox/${inboxsmsID}`)
				.then(response => {
					this.inboxsms = response.data
				})
				.catch(error => {
					console.log(JSON.stringify(error))
				})
		}
	},
}
</script>