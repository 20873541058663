<template>
	<div class="page-outbox-sms">
		
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">SMS Sentitem</h1>
			</div>

			<div class="column is-10 is-offset-1">
				<div class="box">
					<h2 class="subtitle">Details</h2>
					
					<table class="table is-fullwidth">
						<thead>
							<tr>
								<th>Key</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>ID:</td>
								<td>{{ sentitem.id }}</td>
							</tr>
							<tr>
								<td>Sent at:</td>
								<td>{{ sentitem.sendingdatetime }}</td>
							</tr>
							<tr>
								<td>Sent to::</td>
								<td>{{ sentitem.destinationnumber }}</td>
							</tr>
							<tr>
								<td>Text:</td>
								<td>{{ sentitem.textdecoded }}</td>
							</tr>
							<tr>
								<td>Coding:</td>
								<td>{{ sentitem.coding }}</td>
							</tr>
							<tr>
								<td>Udh:</td>
								<td>{{ sentitem.udh }}</td>
							</tr>
							<tr>
								<td>Sent from:</td>
								<td>{{ sentitem.smscnumber }}</td>
							</tr>
							<tr>
								<td>Class:</td>
								<td>{{ sentitem.class_field }}</td>
							</tr>
							<tr>
								<td>Senderid:</td>
								<td>{{ sentitem.senderid }}</td>
							</tr>
							<tr>
								<td>Text raw:</td>
								<td>{{ sentitem.text }}</td>
							</tr>
							<tr>
								<td>Updated in db:</td>
								<td>{{ sentitem.updatedindb }}</td>
							</tr>
							<tr>
								<td>Processed:</td>
								<td>{{ sentitem.processed }}</td>
							</tr>
							<tr>
								<td>Status:</td>
								<td>{{ sentitem.status }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'SentItem',
	data () {
		return {
			sentitem: {},
		}
	},
	mounted() {
		this.getSentItem()
	},
	methods: {
		getSentItem() {
			const sentitemID = this.$route.params.id

			axios
				.get(`/api/v1/sentitems/${sentitemID}`)
				.then(response => {
					this.sentitem = response.data
				})
				.catch(error => {
					console.log(JSON.stringify(error))
				})
		}
	},
}
</script>