<template>
	<div class="page-sent">
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">SMS Sent</h1>
			</div>
			
			<div class="column is-10 is-offset-1">
				<div class="box">
					<h2 class="subtitle">Sent items</h2>
			
					<table class="table is-fullwidth">
						<thead>
							<tr>
								<th>#</th>
								<th>Sent at</th>
								<th>Sent to</th>
								<th>Text</th>
								<th>Status</th>
								<th></th>
							</tr>
						</thead>
			
						<tbody>
							<tr
								v-for="smsText in sentitems"
								v-bind:key="smsText.id"
							>
								<td>{{ smsText.id }}</td>
								<td>{{ smsText.sendingdatetime }}</td>
								<td>{{ smsText.destinationnumber }}</td>
								<td>{{ smsText.textdecoded }}</td>
								<td>{{ smsText.status }}</td>
								<td>
									<router-link :to="{ name: 'SentItem', params: { id: smsText.id }}">Details</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'SentItems',
	data() {
		return {
			sentitems: [],
		}
	},
	mounted() {
		this.getSentItems()
	},
	methods: {
		getSentItems() {
			axios
				.get('/api/v1/sentitems/')
				.then(response => {
					for (let i = 0; i < response.data.length; i++) {
						this.sentitems.push(response.data[i])
						// if (this.sms.length <= 5) {
						// 	this.sms.push(response.data[i])
						// }
					}
				})
				.catch(error => {
					console.log(JSON.stringify(error))
				})
		}
	},
}
</script>